.info-overlay-right {

    position: absolute;
    border-radius: 16px;
    padding: 20px;
    height: 70%;
    background: rgba(0, 0, 0, 0.65);
    top: 200px;
    width: 350px;
    // right: -350px;
    right: 40px;
    z-index: 4;
    
    transition: right 0.3s ease-in-out;
    overflow: hidden;
    color: white;
    display: none;

    &::-webkit-scrollbar {
        width: 10px;
        background-color: $azul-principal;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $azul-aleatorio;
        border-radius: 20px;
        border: 1px solid white;
    }

    &.open {
        // right: 40px;
        display: block;
    }

}

.info-overlay-subtitle {

    position: absolute;
    border-radius: 16px;
    padding: 20px;
    height: 250px;
    background: rgba(0, 0, 0, 0.65);
    width: 350px;
    top: 50vh;
    left: 70px;
    z-index: 4;
    
    transition: right 0.3s ease-in-out;
    overflow: hidden;
    color: white;
    display: none;

    &::-webkit-scrollbar {
        width: 10px;
        background-color: $azul-principal;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $azul-aleatorio;
        border-radius: 20px;
        border: 1px solid white;
    }

    &.open {
        // right: 40px;
        display: block;
    }

    .subtitles {

        margin-top: 20px;

        .subtitle-item {
            font-weight: 600;
            color: #2F3742;
            display: inline-flex;

            span {
                margin-right: 10px;
                color: white;
            }

            i {
                margin-right: 5px;
                line-height: 1.5;
            }

            &.vinculated-selected i {
                color: forestgreen;
            }

            &.vinculated i {
                color: #2094E2;
            }

            &.unvinculated i {
                color: #dcdcdc;
            }

            &.point-selected i {
                color: #EB4034;
            }
        }
    }

}
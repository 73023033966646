.login {

	padding-top: 15%;
	height: 100vh;
	background-color: $cinza-super-claro;
    background-image: url("../../assets/bg/login-bg.svg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .or-container {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 40px;

        span {
            color: white;
            font-weight: 500;
            font-size: 18px;
            line-height: 0;
            margin-left: 20px;
            margin-right: 20px;
        }

        .small-line {
            height: 1px;
            width: 150px;
            background: #6D76F2;
        }
    }

    .pass-group {
        width: 360px;
        position: relative;
        margin: auto;

        i {
            color: white;
            position: absolute;
            right: 15px;
            top: 10px;
        }
    }

	h1 {
		color: white;
        text-align: center;
        font-size: 46px;
        font-weight: 700;
        padding-bottom: 45px;
	}

    .forgot-pass {
        text-decoration: none;
        color: white;
        font-weight: 700;
        font-size: 18px;
        margin-top: 20px;
    }

	.link-login {
		color: white;
		font-weight: bolder;
		text-decoration: underline;
	}

    .login-button {
        background: #FFFFFF;
        border-radius: 6px;
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        color: #2F3742;
        width: 360px;
        margin: auto;
    }

    input {
        background-color: transparent;
        color: white;
        margin-bottom: 16px;
        width: 360px;
        border: 1px solid #DCDCDC;
        margin-left: auto;
        margin-right: auto;

        &::-webkit-input-placeholder {
            color: white !important;
        }

        &:focus, &:active {
            color: white !important;
            background-color: transparent;
            border: 1px solid #DCDCDC;
            box-shadow: none;
        }
    }

	.alert {
		top: 0;
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 18px;
		font-weight: 800;
	}

}

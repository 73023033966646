.new-project-360 {

    .file-input-fake-button {
        margin-right: 10px;
    }

    .upload-button {
        margin-top: 20px;
        margin-right: 20px;
        color: white;
    }

    .calendar-message {
        text-align: center;
        font-weight: bold;
        margin-top: 20px;
        font-size: 24px;
        display: block;
    }

    .subtitles {

        margin-top: 20px;

        .subtitle-item {
            font-weight: 600;
            color: #2F3742;
            display: inline-flex;

            span {
                margin-right: 10px;
            }

            i {
                margin-right: 5px;
                line-height: 1.5;
            }

            &.success i {
                color: forestgreen;
            }

            &.future i {
                color: #2094E2;
            }

            &.incomplete i {
                color: orange;
            }

            &.fail i {
                color: #EB4034;
            }
        }
    }

    .button-footer-group {
        margin-top: 20px;

        button {
            margin-right: 20px;
        }
    }

    .subcard {
        height: 38vh !important;

        .card-header {
            background-color: #DCDCDC;
            color: #422f40;
            font-size: 18px;
            font-weight: 100;
        }
    }

    .card-body {
        overflow-y: auto !important;
        overflow-x: clip;
    }

    .card {
        width: 100%;
        height: 60vh;
    }
    
    @media screen and (max-width: 1366px) {
        .button-group-selection {

            text-align: center;
            margin-bottom: 5px;
    
            .selection-button.selected {
                border: 2px solid #2094E2;
                background-color: #2094E2;
                box-shadow: none;
            }
    
            .selection-button {
                margin-right: 10px;
                font-weight: 700;
                font-size: 17px;
                border: none;
                background-color: #B0ACAC;
            }
        }
    

        .subcard {
            height: 30vh !important;
    
            .card-header {
                background-color: #DCDCDC;
                color: #422f40;
                font-size: 16px;
                font-weight: 100;
            }
        }
    }
}

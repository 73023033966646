.scenes {

    .salvar-button {
        margin-top: 20px;
        margin-right: 20px;
    }

    .passeio-button {
        font-size: 18px;
        color: white;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .botton-card {
        margin-top: 20px;
    }

    .nav-tabs {
        margin-top: 20px;
    }

    .environment-list-wrapper {
    
        .environment-wrapper {

            .scene-list-wrapper {
                max-height: 70vh;
                overflow-y: scroll;
            }

            .environment-name {
                height: 35px;
                padding-top: 5px;
                padding-left: 10px;
                font-weight: bold;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: $azul-principal;
                    color: white;
                }
            }

            .scene-item {
                height: 40px;
                box-shadow: 2px 2px 5px $gray-300;
                border-radius: 5px;
                padding-top: 8px;
                padding-left: 10px;
                margin-left: 30px;
                cursor: pointer;

                &:hover {
                    background-color: $azul-principal;
                    color: white;
                }
            }
        }
    }
}

.modal-alignment {

    max-width: 80%;

    .rotator {
        height: 80vh;
    }

    .split-screen {
        display: inline-flex;
        width: 76vw;
    }
}
.plano-card {

	margin-top: 27px;

	.setup {
		font-size: 25px;
	}

	.blue {
		color: white;
		background-color: $azul-allie;

		i {
			color: white;
		}

		.feature-container {
			border-bottom: 1px solid white;
			padding-bottom: 10px 
		}
	}

	.white {
		color: $azul-allie;
		background-color: white;

		i {
			color: $azul-allie;
		}

		.feature-container {
			border-bottom: 1px solid $azul-allie;
			padding-bottom: 10px 
		}
	}

	.summary {
		font-size: 14px;
	}

	.feature-container {
		text-align: left;
		margin: 15px;

		.feature-item {
			text-align: left;
		}
	}

	.active-el {
		background-color: $verde-permitido;
		color: white;
		width: fit-content;
		font-weight: 600;
		padding: 4px;
		border-radius: 10px;
		top: 15px;
		position: relative;
		z-index: 10;
		left: 25px;
	}

	.card {
		cursor: pointer;
		border: 3px solid $azul-allie;
		border-radius: 10px;
		max-width: 300px;
		text-align: center;
	}

	.card-body {
		padding-left: 0;
		padding-right: 0;
	}

	.plano {
		font-size: 20px;
	}

	.vigencia {
		font-size: 25px;
		font-weight: bold;
	}

	i {
		margin-right: 5px;
		margin-left: 10px;
		color: $cinza-escuro;
	}

	.tarja-metodo {
		margin-top: 10px;
		margin-bottom: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 18px;
		font-weight: 600;
		background-color: $azul-allie;
		color: white;

		i {
			color: white;
		}
	}

	.price-title {
		font-size: 30px;
		font-weight: bold;
	}

	.price-description {
		font-size: 20px;
	}

	.caixa-metodo {
		padding: 15px;
		font-size: 18px;
		font-weight: 600;
		background-color: $azul-allie;
		color: white;

		i {
			color: white;
		}
	}

	.caixa-metodo-card {
		margin-left: 20px;
		margin-right: 20px;

		.card-body {
			padding: 0;
		}
	}

}

.plano-card.active {
	margin-top: 0;
}

.metodo-card {
	.active-el {
		left: 40px;
	}
}

.metodo-container {
	display: inline-flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

@font-face {
	font-family: "n27";
	src: url('./fonts/n27.ttf') format("truetype");
}

@font-face {
	font-family: "std";
	src: url('./fonts/std.ttf') format("truetype");
}

@font-face {
	font-family: "ubuntu";
	src: url('./fonts/Ubuntu-Regular.ttf');
}

a, div, label, span {
	font-family: 'ubuntu';
}

h1 {
	font-family: 'ubuntu';
}

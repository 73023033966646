.clients {

    .second-row-card {
        height: 200px;
    }

    .projects-card {
        margin-top: 20px;
    }

    .new-button {
        background-color: #DCDCDC;
        font-weight: 700;
        font-size: 18px;
        color: #2F3742;
        width: 100%;
        border: none;
    }

    .button-group {

        float: right;

        i {
            background: #2094E2;
            color: white;
            border-radius: 18px;
            font-size: 14px;
            padding: 5px;
            margin-right: 6px;
            margin-bottom: 10px;
            cursor: pointer;
        }
    }

    .clients-list {
        max-height: 650px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 10px;
            background-color: #f5f4f3;
            overflow-x: hidden;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: #2094e2;
            border-radius: 20px;
            border: 1px solid white;
            overflow-x: hidden;
        }
    }

    .help-button {
        float: right;
        color: #2094e2;
        background-color: white;
        border: 2px solid #2094e2;
        border-radius: 25px;
        line-height: 1;
        margin-top: 6px;
        font-size: 20px;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color: #2094e2;
            color: white;
        }
    }

    .collapse-container {

        span {
            font-weight: 100;

        }
    }

    .subcard {

        margin-bottom: 10px;

        .item-subcard-header {
            background-color: #DCDCDC;
            color: #2F3742;
            font-weight: 100;
        }
    }

    .new-button {
        background: linear-gradient(90deg, rgba(32,148,226,1) 0%, rgba(32,148,226,1) 36%, rgba(0,212,255,1) 100%) !important;
        background: rgb(32,148,226);
        font-weight: 700;
        max-width: 550px !important;
        width: 100%;
        color: white;
        border: none;
        border-radius: 25px;
        font-size: 21px;
        text-align: center;
        height: 57px;

        i {
            margin-left: 10px;
        }

    }

    .project-item-card {

        margin-top: 10px;
        border: none;
        display: inline-flex;

        .tour-icon {
            background: $azul-allie;
            color: white;
            border-radius: 18px;
            font-size: 14px;
            padding: 5px;
            margin-right: 6px;
            margin-bottom: 10px;
            cursor: pointer;
            float: right;
        }

        &:hover {
            box-shadow: 2px 4px 11px #888888;
        }

        .tour {
            background-color: #81d4e2;
        }

        .construction {
            background-color: #FADEC9;
        }

        .review {
            background-color: #E8DEEE;
        }

        img {
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 6px;
            width: 100px;
            height: 100px;
        }

        .capsule {
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            border: none;
            color: #010C1C;
            border-radius: 25px;
        }

        .card-header {
            background-color: transparent;
            font-weight: 700;
            font-size: 18px;
            color: #2F3742;
            border: none;
        }

        .card-body {
            padding-bottom: 0;
            padding-top: 0px;
            border: 1px solid lightgray;
            border-top: none;
        }
    }

    .card {
        width: 100%;
    }

    .project-item {

        height: 120px;

        button {
            margin-left: 10px;

            &:first-child i {
                margin-right: 10px;
            }
        }

        i {
            color: white;
        }
    }
}

.modal-projeto {

    label {
        margin-top: 20px;
    }
}
.notification {

    .galery-container {
        display: block;

        .galery-item {
            max-width: 100%;

            img {
                max-height: unset;
            }
        }

    }

    .att-preview {
        height: 400px;
    }

    .att-card {
        height: 400px;
    }

    .att-icon {
        color: white;
        background-color: #2094E2;
        border-radius: 50%;
        padding: 5px;
        font-size: 12px;
    }

    .report-item {
        width: 100%;

        button {
            float: right;
            line-height: 1;
        }
    }

    .divider {
        width: 100%;
        margin-left: 0;
    }

    .tag-container {
        max-height: 40vh;
        overflow-y: scroll;
    }

    .tag-label {
        display: inline-block;
        width: 100%;
        padding: 0.25rem 0.4rem;
        text-align: center;
        border-radius: 0.25rem;
        margin-bottom: 8px;
        background-color: #DCDCDC;
    }

    .tag-card-header {
        color: #2F3742;
        background-color: white;
        font-weight: 200;
        font-size: 14px;
        border: none;

        &:hover {
            cursor: pointer;
            background-color: #2F3742 !important;
            color: white !important;
        }
    }

    .floorplan-list.card {
        margin-bottom: 8px;
        margin-right: 8px;
    }

    .subcard {
        height: 55vh !important;

        .card-header {
            background-color: #DCDCDC;
            color: #2F3742;
            font-size: 18px;
            font-weight: 100;
        }
    }

    .help-button {
        float: right;
        color: #2094e2;
        background-color: white;
        border: 2px solid #2094e2;
        border-radius: 25px;
        line-height: 1;
        margin-top: 6px;
        font-size: 20px;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color: #2094e2;
            color: white;
        }
    }
}
.photogrammetry {

    .enable-photogrammetry {
        width: 300px;
        height: 80px;
        margin: auto;
    }

    .upload-button {
        margin-top: 20px;
        color: white;
    }

    .button-footer-group {
        margin-top: 20px;

        button {
            margin-right: 20px;
        }
    }

    .card-body {
        overflow-y: auto;
        overflow-x: clip;
    }

    .card {
        width: 100%;
        height: 60vh;
    }
}

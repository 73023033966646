.sign-line {

    .alert {
        background-color: #e25b20;
        color: white;

        button {
            background: white !important;
            color: #e25b20;
        }
    }
    
}

.modal-produtos {

    .produto-container {
        display: inline-flex;
        width: 100%;
    }
    
    .modal-content {
        width: fit-content;
    }

    .produto-item {

        &.active {
            box-shadow: 0 0 11px rgba(33,33,33, .8);
            background-color: $azul-allie;
            color: white;
        }

        &:hover {
            box-shadow: 0 0 11px rgba(33,33,33,.2);
        }

        cursor: pointer;
        border-radius: 25px;
        border: 1px solid $azul-allie;
        padding: 10px;
        line-height: 4;
        margin: 15px;
        text-align: center;
        color: $azul-allie;

        img {
            width: 200px;
            height: 200px;
            border-radius: 25px;
        }
    
    }
}

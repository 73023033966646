.project {

    .galery-container {
        grid-template-columns: max-content;
    }

    .photo-preview-container {

        .up-arrow, .down-arrow {
            margin: auto !important;
            margin-top: 5px !important;
            margin-bottom: 5px !important;
        }

        .rotator {
            width: 100%;
        }
    }

    .open-tour {
        padding: 10px 20px;
        border-radius: 25px; /* Rounded corners */
        background-color: #fff;
        font-size: 16px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        outline: none;
    }
    
    .open-tour::before {
        content: '';
        position: absolute;
        top: -1px; right: -1px; bottom: -1px; left: -1px; /* slightly larger than the button to cover border */
        background: linear-gradient(45deg, rgb(32, 148, 226), rgb(0, 212, 255));
        border-radius: 25px; /* Match the button's border-radius */
        z-index: -1;
        animation: gradient-border-animation 3s ease-in-out infinite;
        padding: 4px; /* size of the border */
    }
    
    @keyframes gradient-border-animation {
        0%, 100% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(360deg);
        }
    }
    
    .open-tour:hover::before {
        animation-play-state: running;
    }
    
    .open-tour {
        z-index: 1;
        position: relative;
    }

    .row-margin {
        margin-top: 10px;
    }

    .expand-icon {
        margin-left: 10px;
    }

    .submit {
        margin-left: 20px;
        margin-bottom: 10px;
    }

    .open-tour {
        font-size: 20px;
        font-weight: bold;
    }

    .unfinished {
        color: #ffc107;
        background-color: white;
        padding: 5px;
        border-radius: 10px;
        border: 2px solid #ffc107;
    }

    .finished {
        color: forestgreen;
        background-color: white;
        padding: 5px;
        border-radius: 10px;
        border: 2px solid forestgreen;
    }

    .passeio-button {
        height: 25px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 13px;
        padding-top: 0px;
        color: white;
        background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
        border: none;
        border-radius: 25px;

        &:hover {
            color: white;
            background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
        }
    }

    .clipboard-icon {
        float: right;
        font-size: 20px;
    }

    .tour-icon {
        background: $azul-allie;
        color: white;
        border-radius: 18px;
        font-size: 25px;
        padding: 5px;
        margin-top: 6px;
        margin-right: 16px;
        cursor: pointer;
        float: right;
    }

    .progress-bar-container {
        display: inline-flex;
        width: 100%;
        margin-top: 20px;

        .progress-bar-custom {
            width: 90%;

            &>div>div {
                background-color: $azul-allie !important;
            }

        }

    }

    .tags-container {

        height: 200px;
        overflow-y: scroll;
        
        label {
            padding: 10px;
        }
    }

    .floorplan-list {

        &.card {
            margin-bottom: 15px;

            &:hover {
                box-shadow: 0 0 10px #888888;
                cursor: pointer;
            }
        }

        .card-body {
            padding: 0;
        }

        .card-header {
            background-color: white;
            color: #2F3742;
            font-size: 14px;
        }

        img {
            max-width: 100%;
        }
    }

    .subcard {

        margin-bottom: 10px;

        .item-subcard-header {
            background-color: #DCDCDC;
            color: #2F3742;
            font-weight: 100;
        }
    }

    .main-card {

        span {
            line-height: 2.3;
        }

        .passeio-button {
            font-size: 16px;
            color: white;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 5px;
            float: right;
        }
    }

    .salvar-button {
        margin-top: 20px;
        margin-right: 20px;
    }

}

.modal-alignment {

    max-width: 80%;

    .rotator {
        height: 80vh;
    }

    .split-screen {
        display: inline-flex;
        width: 76vw;
    }
}
.statistics {

    .info-container {
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
        border: 3px solid #2094E2;
        border-radius: 15px;
        padding: 20px;

        i {
            font-size: 40px;
            color: #2094E2;
        }

        label {
            font-size: 28px;
        }
    }

    .remove-padding {
        padding-left: 0;
        padding-right: 0;
    }

    td:first-child,
    th:first-child {
        border-radius: 10px 0 0 10px;
    }

    td:last-child,
    th:last-child {
        border-radius: 0 10px 10px 0;
    }

    .edit-icon {
        color: white;
        background-color: #2094E2;
        border-radius: 50%;
        padding: 5px;
        font-size: 12px;
    }
}
.galery-inspection {

    .regular {
        border-radius: 10px;
        color: white;
        padding: 5px;
        background-color: #2196f3;
    }

    .new {
        border-radius: 10px;
        color: white;
        padding: 5px;
        background-color: #ff9800;
    }

    .good {
        border-radius: 10px;
        color: white;
        padding: 5px;
        background-color: #4caf50;
    }

    .bad {
        border-radius: 10px;
        color: white;
        padding: 5px;
        background-color: #eb4034;
    }

    @media (max-width: 770px) {
        .galery-container {
            column-count: 3 !important;
        }
        
    }

    .galery-container {
        column-count: 5;

        .main_pic {
            box-shadow: 2px 4px 11px #888888;
        }

        .dropdown-menu-custom {
            position: relative;
            top: -200px;
            right: -100%;
            width: 5px;

            &>button {
                background-color: transparent;
                padding: 6px;
                padding-top: 3px;
                padding-bottom: 3px;
                font-size: 22px;
                margin-right: 12px;
            }

            button {
                color: black !important;
            }
        }

        .galery-item {
            display: inline-block;
            width: 97%;
            max-width: 300px;

            .define-main {
                margin-top: 5px;
            }

            input {
                margin-top: -20px;
            }

            img {
                border: 2px solid transparent;
            }

            img:hover {
                border: 2px solid $azul-allie;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }

    img {
        height: 130px;
        width: 100%;
        border-radius: 6px;
    }

    .delete-icon {
        color: #eb4034;
        font-size: 25px;
        position: absolute;
    }
}

.modal-photo {

    aspect-ratio: 1/1;
    max-width: fit-content;
    min-width: 700px;

    .modal-content {
        max-width: 97vw;
        width: fit-content;
        margin: auto
    }

    @media (max-width: 399px) {
        .modal-content {
            margin: initial;
        }
        
    }

    .modal-body {
        height: 80vh;
        overflow-y: scroll;
    }

    img {
        width: 100%;
        height: 100%;
    }
}
.folders {

    .salvar-button {
        margin-bottom: 20px;
        margin-right: 20px;
    }

    .delete-button {
        margin-left: 10px;
    }

}

.label-caption {

	label {
		font-weight: 600;
		letter-spacing: 0.22px;
		margin-bottom: 0;
	}

	.caption {
		color: $gray-600;
	}

	.label {
		color: $cinza-escuro;
	}

	.vermelho-alerta {
		color: $vermelho-alerta;
	}
}

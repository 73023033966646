.schedule {

    .second-row-card {
        height: 450px;
        padding: 0;
    }

    .rbc-event {
        width: fit-content !important;
    }

    .rbc-month-row {
        height: 70px;
        flex-basis: unset;
    }

    .rbc-row-segment {
        overflow: hidden;
    }

    .subtitles {

        margin-top: 20px;

        .subtitle-item {
            font-weight: 600;
            color: #2F3742;
            display: inline-flex;

            span {
                margin-right: 10px;
            }

            i {
                margin-right: 5px;
                line-height: 1.5;
            }

            &.success i {
                color: forestgreen;
            }

            &.future i {
                color: $azul-allie;
            }

            &.incomplete i {
                color: orange;
            }

            &.canceled i {
                color: #EB4034;
            }
        }
    }

    .help-button {
        float: right;
        color: #2094e2;
        background-color: white;
        border: 2px solid #2094e2;
        line-height: 1;
        margin-top: 6px;
        font-size: 20px;
        border-radius: 25px;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color: #2094e2;
            color: white;
        }
    }

    .new-button {
        background: linear-gradient(90deg, rgba(32,148,226,1) 0%, rgba(32,148,226,1) 36%, rgba(0,212,255,1) 100%) !important;
        font-weight: 700;
        color: white;
        max-width: 550px !important;
        width: 100%;
        border: none;
        font-size: 21px;
        text-align: center;
        height: 57px;
        border-radius: 25px;

        i {
            margin-left: 10px;
        }

    }

}

.share {

    .clipboard-icon {
        margin-left: 20px;
    }

    .link-button {
        color: #2094e2;
        background-color: white;
        border: 2px solid #2094e2;
        border-radius: 20px;
        line-height: 1;
        margin-top: 6px;
        font-size: 20px;
        margin-left: 10px;

        i {
            margin-left: 0px;
            padding: 2px;
        }

        &:hover {
            background-color: #2094e2;
            color: white;
        }
    }

    .link-button-delete {
        color: #eb4034;
        background-color: white;
        border: 2px solid #eb4034;
        border-radius: 20px;
        line-height: 1;
        margin-top: 6px;
        font-size: 18px;
        margin-left: 10px;
        padding: 5px;
        cursor: pointer;

        &:hover {
            background-color: #eb4034;
            color: white;
        }
    }

    .new-button {
        background: linear-gradient(90deg, rgba(32,148,226,1) 0%, rgba(32,148,226,1) 36%, rgba(0,212,255,1) 100%) !important;
        background: rgb(32,148,226);
        font-weight: 700;
        color: white;
        max-width: 550px !important;
        width: 100%;
        border: none;
        border-radius: 25px;
        font-size: 21px;
        text-align: center;
        height: 57px;

        i {
            margin-left: 10px;
        }

    }

    .help-button {
        float: right;
        color: #2094e2;
        background-color: white;
        border: 2px solid #2094e2;
        border-radius: 25px;
        line-height: 1;
        margin-top: 6px;
        font-size: 20px;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color: #2094e2;
            color: white;
        }
    }
}
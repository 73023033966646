.users {

    td:first-child,
    th:first-child {
        border-radius: 10px 0 0 10px;
    }

    td:last-child,
    th:last-child {
        border-radius: 0 10px 10px 0;
    }

    .edit-icon {
        color: white;
        background-color: #2094E2;
        border-radius: 50%;
        padding: 5px;
        font-size: 12px;
    }

    .new-button {
        background: linear-gradient(90deg, rgba(32,148,226,1) 0%, rgba(32,148,226,1) 36%, rgba(0,212,255,1) 100%) !important;
        background: rgb(32,148,226);
        font-weight: 700;
        color: white;
        max-width: 550px !important;
        width: 100%;
        border: none;
        font-size: 21px;
        text-align: center;
        height: 57px;
        border-radius: 25px;

        i {
            margin-left: 10px;
        }

    }

    .help-button {
        float: right;
        color: #2094e2;
        background-color: white;
        border: 2px solid #2094e2;
        border-radius: 25px;
        line-height: 1;
        margin-top: 6px;
        font-size: 20px;
        border-radius: 25px;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color: #2094e2;
            color: white;
        }
    }
}
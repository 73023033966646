.projects-pms {

    .second-row-card {
        height: 450px;
        padding: 0;
    }

    .title {
        margin-bottom: 1px solid lightgray;
        font-weight: 800;
        background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
        border-radius: 5px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
        color: white;
    }

    .subtitles {

        margin-top: 20px;

        .subtitle-item {
            font-weight: 600;
            color: #2F3742;
            display: inline-flex;

            span {
                margin-right: 10px;
            }

            i {
                margin-right: 5px;
                line-height: 1.5;
            }

            &.success i {
                color: forestgreen;
            }

            &.future i {
                color: $azul-allie;
            }

            &.incomplete i {
                color: orange;
            }

            &.fail i {
                color: #EB4034;
            }
        }
    }

    .tags-container {

        display: inline-flex;
        
        .tag-item {
            border: 1px solid $azul-allie;
            color: $azul-allie;
            padding: 5px;
            border-radius: 15px;
            margin-right: 10px;
            margin-bottom: 20px;
        }
    }

    .filter-container {

        width: 100%;
        display: inline-flex;

        i {

            &.remove {
                color: #EB4034;
            }

            padding: 12px;
            width: 40px;
            height: 40px;
            background-color: lightgray;
            border-radius: 5px;
            margin-left: 10px;
            margin-top: 20px;
            cursor: pointer;
        }

        .filter-input {
            margin-top: 20px;
            margin-bottom: 20px;
            width: 100%;
            border: 1px solid lightgray;
            border-radius: 6px;
            height: 40px;
            padding-left: 10px;
            font-size: 16px;
            color: #2F3742;
        }
    }

    .collapse-container {

        span {
            font-weight: 100;

        }
    }

    .new-button {
        background: linear-gradient(90deg, rgba(32,148,226,1) 0%, rgba(32,148,226,1) 36%, rgba(0,212,255,1) 100%) !important;
        font-weight: 700;
        color: white;
        max-width: 550px !important;
        width: 100%;
        border: none;
        font-size: 21px;
        text-align: center;
        height: 57px;
        border-radius: 25px;

        i {
            margin-left: 10px;
        }

    }

    .button-group {

        float: right;

        i {
            background: $azul-allie;
            color: white;
            border-radius: 18px;
            font-size: 14px;
            padding: 5px;
            margin-right: 6px;
            margin-bottom: 10px;
            cursor: pointer;
        }
    }

    .notifications {
        margin-top: 10px;
        background-color: #f5f4f3;
        width: 100%;
        height: 120px;
        border-radius: 6px;
        display: flex;
        align-items: center;

        span {
            font-weight: 600;
            font-size: 16px;
            color: #2F3742;
        }

        button {
            color: white;
            position: absolute;
            right: 50px;
            border-radius: 25px;
            font-weight: 800;
        }

        i {
            color: #2F3742;
            margin-left: 20px;
            margin-right: 10px;

        }

        img {
            width: 50px;
            margin-left: 20px;
        }

        .current {

            display: inline-flex;

            .temperature {
                display: grid;
                margin-top: 10px;
            }

            .weekday {}
        }

        .future-container {

            display: inline-flex;
            position: absolute;
            right: 40px;

            .future {

                margin-right: 30px;

                .temperature {
                    margin-left: 20px;
                }

                .weekday {
                    margin-left: 35px;
                }
            }
        }

    }

    .project-item-card {

        margin-top: 10px;
        border: none;
        cursor: pointer;

        .tour-icon {
            background: $azul-allie;
            color: white;
            border-radius: 18px;
            font-size: 14px;
            padding: 5px;
            margin-right: 6px;
            margin-bottom: 10px;
            cursor: pointer;
            float: right;
        }

        &:hover {
            box-shadow: 2px 4px 11px #888888;
        }

        .tour {
            background-color: #81d4e2;
        }

        .construction {
            background-color: #FADEC9;
        }

        .review {
            background-color: #E8DEEE;
        }

        .project-number {
            width: 40px;
            display: inline-table;
        }

        .capsule {
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            border: none;
            color: #010C1C;
            border-radius: 25px;
            float: right;
            margin-right: 10px;
        }

        .card-header {
            background-color: transparent;
            font-weight: 400;
            font-size: 24px;
            color: #2F3742;
            border: none;
        }

        .card-body {
            padding-bottom: 0;
            padding-top: 0px;
            margin-left: 10px;
            border: 1px solid lightgray;
            border-top: none;
        }
    }

    .card {
        width: 100%;
    }

    .project-item {

        height: 120px;

        button {
            margin-left: 10px;

            &:first-child i {
                margin-right: 10px;
            }
        }

        i {
            color: white;
        }
    }
}

.modal-projeto {

    label {
        margin-top: 20px;
    }
}

.modal-calendar {

    .rbc-event {
        width: 10px !important;
        height: 10px;
    }

    .rbc-month-row {
        height: 35px;
        flex-basis: unset;
    }

    .rbc-row-segment {
        overflow: hidden;
    }

    .second-row-card {
        height: 450px;
        padding: 0;
    }

    .subtitles {

        margin-top: 20px;

        .subtitle-item {
            font-weight: 600;
            color: #2F3742;
            display: inline-flex;

            span {
                margin-right: 10px;
            }

            i {
                margin-right: 5px;
                line-height: 1.5;
            }

            &.success i {
                color: forestgreen;
            }

            &.future i {
                color: $azul-allie;
            }

            &.incomplete i {
                color: orange;
            }

            &.fail i {
                color: #EB4034;
            }
        }
    }
}
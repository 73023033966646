body {
    overflow: auto !important;
}

.body {
    margin-top: 30px;
}

.header-wrapper {
    float: right;
}

.header {

    &>.container-fluid:first-child {
        margin-left: auto;
        margin-right: 0;
        width: unset;
    }
}

.container-lg {
    max-width: unset;
}

.side-menu-icon {
    margin-right: 10px;
}

.file-input {
    display: none;
}

.file-input-fake-button {
    color: white;
    background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
    border: none;
    border-radius: 25px;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    margin-top: 20px;
    cursor: pointer;

    &:hover {
        color: white;
        background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
    }
}

.divider {
    border: 1px solid $gray-100;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.hidden {
    display: none;
}

.pointer {
    cursor: pointer;
}

.grab {
    cursor: grab;
}

.grabbing {
    cursor: grabbing;
}

.spinner-results {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 58px;
    z-index: 10000;
    // background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-radius: 30px;
}

.modal-pdf {

    margin-left: 20%;

    .modal-content {
        width: 70vw;
        text-align: center;
        text-align: -webkit-center;
    }

    .modal-body {
        max-height: 73vh;
        overflow-y: scroll;
    }
}

@media screen and (min-width: 1024px) {
    .alert {
        padding-left: 270px;
    }
}

.alert {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 0;

    button {
        margin-left: 10px;
    }
}

.save-button {
    background-color: #2094E2;
    border: none;
    color: white;
}

.bg-light {
    background-color: white !important;
}

.header {
    border: none;
}

.username-container {
    
    span {
        font-size: 18px;
        color: #2094E2;
        font-weight: bold;
        margin-left: 6px;
    }
}

.notification-icon-container {
    
    span {
        font-size: 18px;
        color: #2094E2;
        font-weight: bold;
        margin-left: 6px;
    }

    i {
        transform: rotate(-45deg);
    }
}

.badge-pill {
	transform: rotate(0deg);
    position: absolute;
	margin-left: -14px !important;
    color: white !important;
	font-size: 10px !important;
}

.logout-button {
    background-color: $azul-allie;
    transform: scaleX(-1);
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 25px;
    line-height: 1.7;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
    margin-bottom: 20px;
    margin-left: 18px;
}

.button-footer-group {

    float: right;
    margin-top: 20px;
    margin-right: 80px;
    margin-bottom: 20px;

    button {
        font-size: 25px;

        &:first-child {
            margin-right: 20px;
        }
    }

}

.rbc-event {
    width: 38px !important;
    height: 23px;

    .rbc-event-content {
        line-height: 1.2;
        overflow: visible !important;
    }
}

iframe {
    margin-right: 20px;
}

@media (max-width: 399px) {
    .steps {
        display: none;
    }
    
}

@media (max-width: 770px) {

    .wrapper {
        padding-left: 50px !important;
    }

    .sidebar {
        width: 50px;
        margin-left: 0 !important;

        .icon-text {
            display: none;
        }
    }

    .logout-button {
        margin-left: 5px;
    }
    
}


.steps {
    margin-bottom: 20px;
    cursor: pointer;
}

.cancel-button {
    color: $azul-allie;
    background-color: white;
    border: 1px solid $azul-allie;
    border-radius: 25px;

    &:hover {
        color: white;
        background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
    }
}

.ok-button {
    color: white;
    background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
    border: none;
    border-radius: 25px;

    &:hover {
        color: white;
        background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
    }
}

.mobile-button {
    color: white;
    background: #2EA3F2;
    border: none;
    border-radius: 9px;
}

.cancel-mobile-button {
    background: white;
    color: #2EA3F2;
    border: none;
    border-radius: 9px;
}

.capture-button {
    color: white;
    background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
    border: none;
    border-radius: 25px;
    padding: 30px;
}

.custom-event {
    display: inline-flex;

    .icon {
        margin-left: 15px;
        color: #F54D4D;
    }
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgba(32, 148, 226, 0.25);
}

.__floater {
    z-index: 20000 !important;
}

.fallback-spinner {
    z-index: 10000;
    position: absolute;
    top: 50% ;
    left: 50%;
    transform: translate(-50%, -30%);
}
.inspection-pms {

    .header-container {
        display: inline-flex;

        .checkbox-enable {
            margin-right: 10px;
            width: 80px;
            border-radius: 15px;
            color: #2094e2;
        }

        .card-header {
            width: 100%;
            font-size: 20px;
        }

        .unchecked {
            color: #858687;
            background-color: #E6E6E6;
        }
    }

    .upload-button {
        margin-top: 20px;
        color: white;
    }

    .galery-container {
        column-count: 3;

        img {
            width: 100px;
            height: 130px;
        }
    }

    .card {
        margin-bottom: 20px;
        
        .card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .subcard {
        height: 38vh !important;

        .card-header {
            background-color: #DCDCDC;
            color: #422f40;
            font-size: 18px;
            font-weight: 100;
        }
    }
    
}

.modal-photo-pms {
    min-width: unset !important;
}

.fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 15px;

    i {
        background-color: white;
        font-size: 68px;
        color: $azul-allie;
        border-radius: 50%;
        border: none;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
        cursor: pointer;
        outline: none;
    }
}


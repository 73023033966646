.planos {

	.compra-repetida {
		text-align: center;
		background-color: $cinza-claro;
		padding: 20px;
		border-radius: 20px;
		margin: 20px;

		h1 {
			color: $verde-permitido;
		}
	}

	h1 {
		color: $cinza-escuro;
		margin-left: 15px;
		font-size: 26px;
	}

	.block-item {
		display: flex;

		i {
			padding-top: 5px;
		}
	}

	.resumo-compra {
		-webkit-box-shadow: -4px 2px 18px -2px rgba(97,97,97,1);
		-moz-box-shadow: -4px 2px 18px -2px rgba(97,97,97,1);
		box-shadow: -4px 2px 18px -2px rgba(97,97,97,1);
		padding: 20px;
        margin-top: 20px;
	}

	h2 {
		color: $cinza-escuro;
	}

	button {
		margin-top: 20px;
	}

	.resumo {

		.label-caption {
			margin-top: 10px;
		}
	}

	.ajuda-container-light {
		width: 100%;
		border-radius: 35.5px;

		span, i {
			line-height: 2.5;
			padding-right: 5px;
			font-weight: 600;
			color: $cinza-escuro;
		}

		i {
			color: $cinza-escuro;
		}
	}

	.parcelas-container {

		label {
			color: $cinza-escuro;
		}

		i {
			color: $cinza-escuro;
			margin-right: 10px;
		}
	}

	.opcao-label {
		font-size: 18px;
		font-weight: 600;
	}

	.Select-input {

		input {
			border: none !important;
		}
	}

	.Select-value {
		border-radius: 15px !important;
		background-color: white !important;
		padding-left: 5px;
		padding-right: 5px;
		font-weight: 600;
		color: $azul-secundario !important;
		border-color: $azul-secundario !important;
	}
}

.modal-confirmar-compra {

	.row {
		margin: auto;
		text-align: left;
	}

    .btn {
        margin-right: 20px;
    }
    
}

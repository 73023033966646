.mobile {

	*:not(i) {
		font-family: 'Open Sans', sans-serif;
	}

	input {
		border-radius: 8px;
		border: 1px solid #E4E4E4;
		margin-bottom: 10px;
	}

	label {
		color: #4B585E;
	}

	h1, h2, h3 {
		color: black;
	}

	img {
		width: -webkit-fill-available !important;
	}

	.tag-select {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

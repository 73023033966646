.galery {

    .galery-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;

        .dropdown-menu-custom {
            position: relative;
            top: -125px;
            right: -100%;

            &>button {
                background-color: transparent;
                padding: 6px;
                padding-top: 3px;
                padding-bottom: 3px;
                font-size: 22px;
                margin-right: 12px;
            }

            button {
                color: black !important;
            }
        }

        .galery-item {
            display: inline-block;
            width: 97%;
            max-width: 300px;

            .define-main {
                margin-top: 5px;
            }

            img {
                border: 2px solid transparent;
            }

            img:hover {
                border: 2px solid $azul-allie;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }

    img {
        max-height: 130px;
        width: 100%;
        border-radius: 6px;
    }

    .delete-icon {
        color: #eb4034;
        font-size: 25px;
        position: absolute;
    }
}

.modal-photo {

    aspect-ratio: 1/1;
    max-width: fit-content;
    min-width: 700px;

    .modal-body {
        height: 80vh;
        overflow-y: scroll;
    }

    img {
        width: 100%;
        height: 100%;
    }
}
.card {

    .card-header {
        border-radius: 6px 6px 0px 0px;
        color: #2F3742;
        font-weight: 700;
        font-size: 25px;
    }
}

.card-borderless {

    border: none;

    .card-gradient.card-header {
        background: linear-gradient(90deg, rgba(32,148,226,1) 0%, rgba(32,148,226,1) 36%, rgba(0,212,255,1) 100%) !important;
        font-weight: 700;
        color: white;
    }

    .card-header {
        border: none;
        border-radius: 6px;
        color: #2F3742;
        background-color: #E6E6E6;
        font-weight: 700;
        font-size: 25px;
    }
}

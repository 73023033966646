.inspection {

    .contestation {

        .card-header {
            background-color: $orange;
        }
    }

    .contestation-icon {
        color: $orange;
        padding-left: 10px;
    }

    .no-inspection-message {
        text-align: center;
        font-size: 25px;
        margin: 20px;
    }

    .upload-button {
        margin-top: 20px;
        color: white;
    }

    .card {
        margin-bottom: 20px;
    }

    .subcard {
        height: 38vh !important;

        .card-header {
            background-color: #DCDCDC;
            color: #422f40;
            font-size: 18px;
            font-weight: 100;
        }
    }
    
}

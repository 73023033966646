.new-project {

    .new-client {
        margin-top: 10px;
    }

    .map-container {
        height: 50vh;
        margin-top: 20px;

        .location-pin {

            i {
                color: #F54D4D; 
                font-size:30px;
                animation: pulse 1s infinite;
            }
    
            @keyframes pulse {
                0% {
                  transform: scale(1);
                  opacity: 1;
                }
                50% {
                  transform: scale(1.1);
                  opacity: 0.7;
                }
                100% {
                  transform: scale(1);
                  opacity: 1;
                }
              }
        }
    }

    .hack-uf-field {
        z-index: 0 !important;
    }

    .select-ufs {
        z-index: 100;

        div:nth-child(2) {
            z-index: 20000;
        }
    }

    .button-group-selection {

        text-align: center;
        margin-bottom: 20px;

        .selection-button.selected {
            border: 3px solid #2094E2;
            box-shadow: none;
        }

        .selection-button {
            margin-right: 10px;
            font-weight: 700;
            font-size: 18px;
            border: none;
            background-color: #2094E2;
        }
    }

    .card-body {
        overflow-y: auto;
    }

    .leaflet-container {
        height: 100%;
    }

    .map {
        width: 100%;
        height: 300px;
        margin: auto;
        margin-bottom: 20px;
    }

    .card {
        width: 100%;
        height: fit-content;
        margin-bottom: 20px;
    }

    .custom-datepicker {
        width: 100%; // Define o DatePicker para ocupar 100% da largura disponível
        font-size: 14px;
    }
    
    @media (max-width: 998px) {
        .first-line {
            width: 50%;
            float: left;
        }
    
        .area-col {
            width: 100%;
            clear: both;
          }
    
        .custom-datepicker-col {
            padding: 0 10px; // Adapte o espaçamento conforme necessário
        }
        
        .custom-datepicker {
            width: 100%; // Define o DatePicker para ocupar 100% da largura disponível
            font-size: 14px;
        }
          
      }

}

.modal-polygon {

    width: 900px;
    max-width: unset;

    .leaflet-container {
        width: 865px;
        height: 400px;
    }
}

.button-group-selection {

    text-align: center;
    margin-bottom: 20px;

    .selection-button.selected {
        border: 3px solid #2094E2;
        background-color: #2094E2;
        box-shadow: none;
    }

    .selection-button {
        margin-right: 10px;
        font-weight: 700;
        font-size: 18px;
        border: none;
        background-color: #B0ACAC;
    }
}

.modal-client {

    .thumb {
        margin-top: 20px;
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 500px) {

    .galery .galery-container {
        grid-template-columns: none;
    }

    .card-body {
        padding-left: 0;
        padding-right: 0;
    }
}
.sidebar {

    // box-shadow: 2px 4px 11px #888888;
    background-color: #f5f4f3;
    color: white;

    a {
        font-family: 'n27';
    }

    .sidebar-nav {

        margin-top: 45px;
        background-color: #f5f4f3;
        color: white;

        .nav-link {
            color: #2e2e2e;
            // font-weight: bold;
            font-size: 20px;

            i {
               color: #b8b8b8; 
            }

            &.active {
                color: $azul-allie;
                background: none;
                font-weight: 800;

                i {
                    color: $azul-allie;
                }
            }
        }
    }

    .sidebar-brand {
        background-color: #f5f4f3;
        margin-top: 45px;
    }

    .sidebar-brand-full {
        height: 80px;
    }
}

.sidebar-narrow-unfoldable {

    .icon-text {
        display: none;
    }
}
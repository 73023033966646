.register {

    padding-top: 15%;
    height: 100vh;
    background-color: $cinza-super-claro;
    background-image: url("../../assets/bg/signup-bg.svg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .form-check-label {
        input {
            margin-right: 5px;
        }
    }

    .select-wrapper {

        margin-top: -28px;

        .select-label {
            position: relative;
            top: 27px;
            left: 10px;
            z-index: 10;
            color: #6E6F71;
        }
    
        .select-ufs {
            margin-bottom: 15px;

            div:nth-of-type(2) {
                z-index: 15;
            }
    
            div {
    
                &:first-child {
                    padding-top: 15px;
                }

            }
        }
    }

    .alert {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;
    }

    .strength-bar {
        margin-bottom: 10px;
    }

    .password-field {
        position: relative;

        .eye-password {
            position: absolute;
            top: 20px;
            right: 30px;
        }
    }

    .card {
        position: absolute;
        width: 450px;
        top: 40px;
        margin-left: 55%;
        overflow-y: auto;
        max-height: 90vh;
    }

    .register-button {
        background-color: #2094E2;
        color: white;
        font-size: 18px;
        font-weight: 500;
        border-radius: 6px;
        margin: auto;
        width: 416px;
        border: none;
        padding: 13px;
        margin-bottom: 10px;
    }

    .registered-label {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
    }

    input {
        background-color: transparent;
        margin-bottom: 16px;
        border: 1px solid #DCDCDC;

        &:focus,
        &:active {
            background-color: transparent;
            border: 1px solid #DCDCDC;
            box-shadow: none;
        }
    }

}
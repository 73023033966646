.modal-notifications {

	.modal-header {
		background: linear-gradient(90deg, rgba(32,148,226,1) 0%, rgba(32,148,226,1) 36%, rgba(0,212,255,1) 100%) !important;
        font-weight: 700;
        color: white;

		.modal-title {
			font-size: 24px;
			font-weight: 700;
			color: white;
			padding-left: 25px;
		}

		i {
			position: absolute;
			left: -15px;
			top: 15px;
			font-size: 18px;
			color: #2daae1;
			background-color: #fff;
			padding: 10px 16px 10px 15px;
			border-radius: 2em;
			cursor: pointer;
		}
	}

	.modal-content {
		width: 100%;
		margin: 0;
		top: -28px;
		position: absolute;
		right: -1600px;
		height: 100vh;
		transition: 0.5s;

		-webkit-box-shadow: -10px 0 30px rgba(0,0,0,0.3);
		-moz-box-shadow: -10px 0 30px rgba(0,0,0,0.3);
		box-shadow: -10px 0 30px rgba(0,0,0,0.3);
	}

	.modal-body {
		overflow-y: auto;
		padding: 0;
	}

	@media (max-width: 399px) {
		.modal-content {
			width: 75%;
		}

		&.slide {

			.modal-content {
				right: calc(-50vw + 188px);
				margin-top: 20px;
				transition: 0.5s;
			}
		}
		
	}

	&.slide {

		.modal-content {
			right: calc(-50vw + 250px);
			transition: 0.5s;
		}
	}

	.capsule {
		border-radius: 35.5px;
		background-color: #43535a;
		margin: 20px;

		span, i {
			line-height: 2.5;
			padding-right: 5px;
			font-weight: 600;
			font-size: 16px;
			color: white;
		}

		i {
			padding-left: 10px;
		}
	}

	.notification-item {

		.card {
			margin-top: 10px;
			margin-bottom: 10px;
			margin-left: 20px;
			margin-right: 20px;
		}
	
		.card-body {
			padding-left: 20px;
			padding-right: 20px;
		}
	
		.data {
			position: absolute;
			right: 0;
			color: #bdc5c8;
			font-weight: 600;
			width: fit-content;
		}
	
		.titulo {
			color: #bdc5c8;
			font-weight: 600;
			font-size: 16px;
		}
	
		.conteudo {
			color: #bdc5c8;
		}
	
	}
	
	.notification-item.unseen {
	
		.card {
			border-color: #43535a;
		}
	
		label {
			color: #43535a;
			width: fit-content;
		}
	}
}

.currency-input {

    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--cui-input-color, rgba(18, 20, 22, 0.95));
    background-color: var(--cui-input-bg, #fff);
    background-clip: padding-box;
    border: 1px solid var(--cui-input-border-color, #acb4bc);
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

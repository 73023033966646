.visualizador-3d {

	height: 70vh;

	.canvas-container {
		margin: auto;
		width: 100%;
		height: 100%;
	}

	.canvas-row {
		height: 100%;
	}
}

.order {

    .fields-list {

        .field-item {
            padding: 10px;
            margin: 5px;
            border-bottom: 1px solid #ddd;
            cursor: grab;

            &:active {
                cursor: grabbing;
            }

            i, li::marker {
                font-size: 24px;
            }

            label {
                margin-left: 30px;
                margin-right: 30px;
                font-size: 24px;
            }

            img {
                max-height: 130px;
                padding-left: 20px;
            }
        }
    }

}

.viewer-360-mobile-diff {

    height: 100vh;
    width: 100vw;
    overflow: hidden;

    canvas {
        touch-action: none;
    }

    .floorplan-container.retracted {
        transition: transform 0.5s linear;
        transform: translateX(-320px);
        animation-name: tr;
        animation-duration: 0.7s;
        animation-delay: 2s;
    }

    .floorplan-container.zoom-1 {
        width: 320px;
        height: 335px;

        .canvas {
            height: 215px !important;
        }
    }

    .floorplan-container.zoom-2 {
        width: 640px;
        // height: 432px;

        .canvas {
            height: 285px !important;
        }
    }

    .floorplan-container.zoom-3 {
        width: 877px;
        // height: 435px;

        .canvas {
            height: 551px !important;
        }
    }

    .floorplan-container {
        transition: transform 0.5s linear;
        position: absolute;
        z-index: 22;
        left: 50px;
        top: 70px;
        background-color: white;
        padding: 15px;
        border-radius: 12px;
        border-top-right-radius: 0px !important;

        .canvas {
            margin-top: 20px;
            // height: 215px !important;
        }

        .floorplan-menu {

            margin-top: 10px;

            .icon-zoom {
                background: $azul-allie;
                color: white;
                border-radius: 18px;
                font-size: 18px;
                padding: 5px;
                margin-right: 6px;
                margin-bottom: 10px;
                cursor: pointer;
                float: right;
            }
        }

        .floorplan-toggler {
            position: absolute;
            top: 0px;
            right: -39px;
            width: 39px;
            height: 45px;
            border-radius: 0px 12px 12px 0px;
            background-color: #DCDCDC;
            color: white;
            font-size: 20px;
            cursor: pointer;

            i {
                line-height: 2.5;
                color: #B0ACAC;
                padding-left: 10px;
            }
        }
    }

    .solved {
        color: #7a9b5f;
    }

    .low {
        color: $azul-allie;
    }

    .medium {
        color: #f0bb19;
    }

    .high {
        color: #cc3816;
    }

    .canvas-container {
        margin: auto;
        width: 100%;
        height: 100%;
    }

    .canvas-row {
        height: 100%;
    }

    .history-container {
        width: 240px;
        display: inline-flex;
        position: fixed;
        left: 30%;
        top: 10px;
        z-index: 25;
        color: $gray-base;
        padding: 15px;
        border-radius: 15px;
        text-align: center;

        .history-select {

            &>div {
                background-color: $azul-allie;
                border: none;

                &>div>div {
                    color: white;
                }
            }

            div[class^="menu"] {
                &:hover {
                    background-color: black;
                }
            }
        }

        h4 {
            color: white;
        }

        .toggle-diff-icon {
            color: white;
            background-color: $azul-allie;
            margin-right: 15px;
            font-size: 20px;
            // border: 3px solid $azul-allie;
            border-radius: 7px;
            padding: 7px;
        }
    }

    .lock-button {
        width: 50px;
        height: 50px;
        position: fixed;
        left: 48.8%;
        top: 20%;
        z-index: 2;
        padding: 15px;
        border-radius: 50%;
        border: none;
        text-align: center;
        background-color: black;
        color: white;

        i {
            font-size: 16px;
        }
    }

    .camera-height-container {
        position: absolute;
        bottom: 15px;
        left: -135px;
        border-radius: 15px;
        padding: 8px;
        text-align: center;
        min-width: 120px;
        background-color: #B0ACAC;
        border-radius: 6px;
        color: white;

        input {
            width: 120px;
        }

        button {
            margin-top: 3px;
            width: 120px;
        }
    }

    .timestamp-container {
        position: absolute;
        top: 20px;
        right: 20px;
        border-radius: 15px;
        z-index: 24;
        padding: 8px;
        text-align: center;
        min-width: 120px;
        background: rgba(0, 0, 0, 0.65);
        border-radius: 6px;
        color: white;

        label {
            margin: 0;
            font-size: 24px;
        }
    }

    .toolbar {

        display: inline-flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 24;
        border-radius: 15px;
        padding: 20px;

        .right-portion {
            background-color: #DCDCDC;
            height: 90px;
            width: 132px;
            border-radius: 6px;

            i {
                margin-left: 7px;
                margin-top: 7px;
            }
        }

        i {
            font-size: 20px;
            width: 34px;
            height: 34px;
            padding: 7px;
            border-radius: 18px;
            color: white;
            background-color: #B0ACAC;
            text-align: center;

            &:first-child {
                // margin-left: 10px;
            }
        }
    }

    .icon-delete-adjacency {
        color: #eb4034;
        width: 20px;
        text-align: center;
        height: 20px;
        font-size: 20px;
        border-radius: 20px;
        background-color: white;
        right: -5px;
        top: -7px;
        position: absolute;
    }

    .tour-tag {
        color: $azul-allie;
    }

    .icon-comment {
        font-size: 60px;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;

        &:hover {
            text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white;
        }
    }

    .tag-text {
        background-color: white;
        border-radius: 12px;
        padding: 10px;
        text-align: center;
        min-width: 100px;
        // margin-left: -25px;
    }

    .icon-adjacency {
        font-size: 60px;
        color: white;
        text-shadow: -1px 0 $azul-allie, 0 1px $azul-allie, 1px 0 $azul-allie, 0 -1px $azul-allie;

        &:hover {
            text-shadow: -3px 0 $azul-allie, 0 3px $azul-allie, 3px 0 $azul-allie, 0 -3px $azul-allie;
        }
    }

    .add-alert {
        margin: 0;
        margin-bottom: -58px;
        z-index: 4;
        text-align: center;
    }

    .canvas-instance {
        width: 50% !important;
        position: fixed !important;
    }

    .canvas-2 {
        left: 50%;
        border-left: 5px solid black;
    }

    .toggle-history {
        margin-left: -25%;
    }

    .toggle-history-2 {
        margin-left: 25%;
    }

    .switch-floorplan-left {
        font-size: 50px;
        color: white;
        position: absolute;
        left: -190px;
    }

    .switch-floorplan-right {
        font-size: 50px;
        color: white;
        position: absolute;
        left: 150px;
    }

    .ifc-button {
        color: white;
        margin-left: 170px;
        position: absolute;
    }

    .ifc-viewer-container {
        position: absolute;
        left: 50%;
        height: 100vh;
        width: 50vw;

        canvas {
            display: initial !important;
            width: 100% !important;
            height: 100vh !important;
        }
    }
}

.modal-tag-360 {

    .tour-tag-types {
        text-align: center;

        i {
            font-size: 27px;
            color: white;
            background-color: #2094E2;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            padding: 11px;
            margin-left: 10px;
            margin-top: 10px;
            border: 5px solid transparent;
            cursor: pointer;

            &.active {
                border: 5px solid green;
            }
        }
    }
}

.modal-tag-mobile {

    .modal-content {
        width: 95vw !important;
        height: unset !important;

        .tag-select,
        input {
            margin-bottom: 20px;
            border-radius: 10px;

            &>div {
                border-radius: 10px;
                text-align: left;
            }
        }

        .file-square {
            background-color: #DCDCDC;
            width: 95vw;
        }

        .tag-select-p {
            width: 100%;
            margin-top: 0px;
            right: 25px;
            position: static;
        }

        .plus-icon {
            font-size: 15px;
            color: white;
            background-color: #2094E2;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            padding: 5px;
            margin-left: 10px;
            margin-top: 120px;
            cursor: pointer;
        }

        .input-fake-button {
            display: none;
        }

        .save-button {
            width: 100px;
            color: white;
            background-color: $azul-allie;
            border: none;
        }

        .delete-button {
            width: 100px;
            color: white;
            background-color: #F54D4D;
            border: 1px solid #F54D4D;
            border-radius: 25px;
        }

    }
}

.micro-thumb {
    margin-right: 15px;
}

// .modal-tag .modal-content {
//     width: 96vw;
//     height: fit-content;
// }

.modal-share {

    .ok-button {
        color: white;
        background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
        border: none;
        border-radius: 25px;
    
        &:hover {
            color: white;
            background: linear-gradient(90deg, rgb(32, 148, 226) 0%, rgb(32, 148, 226) 36%, rgb(0, 212, 255) 100%) !important;
        }
    }

    .modal-content {
        width: 500px;
        margin: auto;

        .link-button {
            color: #2094e2;
            background-color: white;
            border: 2px solid #2094e2;
            border-radius: 20px;
            line-he••••er {
                background-color: #2094e2;
                color: white;
            }
        }
    }
}
.galery-slider {

    height: 380px;
    margin-bottom: 20px;

    .vert-arrow {
        background-color: #DCDCDC;
        border-radius: 6px;
        width: 20px;
        cursor: pointer;

        i {
            line-height: 17;
            margin-left: 5px;
        }
    }

    .hor-arrow {
        background-color: #DCDCDC;
        border-radius: 6px;
        width: 91%;
        height: 20px;
        cursor: pointer;

        i {
            margin-left: 50%;
        }
    }

    .up-arrow, .down-arrow {
        margin-left: 22px;
        margin-top: 3px;
    }

    .right-arrow {
        margin-left: 5px;
    }

    .left-arrow {
        margin-right: 5px;
    }

    .galery-container {
        height: 92%;
        overflow: hidden;
        display: flex;
    }

    img {
        width: 90%;
        object-fit: cover;
    }
}
.side-detail {

    position: absolute;
    top: 0px;
    left: -400px;
    height: 100%;
    width: 400px;
    z-index: 4;
    transition: left .3s ease-in-out;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 0.65);

    &::-webkit-scrollbar {
        width: 10px;
        background-color: $azul-principal;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $azul-aleatorio;
        border-radius: 20px;
        border: 1px solid white;
        overflow-x: hidden;
    }

    .sort-icon {
        float: right;
    }

    audio {
        width: 100%;
    }

    .card {
        background-color: transparent !important;
    }

    .card-header {
        background-color: #2094E2;
        color: white !important;
        border-radius: 12px !important;
        font-size: 20px !important;
        cursor: pointer;
    }

    .file-container {

        .file-item {
            padding: 5px;
            cursor: pointer;
            padding-bottom: 20px;
            border: 1px solid white;
            margin-top: 10px;
            width: 90%;

            div {
                display: inline-flex;
                width: 100%;
            }
        }

        .file-item-image {
            padding: 5px;
            cursor: pointer;
            padding-bottom: 20px;
            border: 1px solid white;
            margin-top: 10px;
            width: 90%;

            img {
                width: 100%;
            }
        }

        label {
            cursor: pointer;
        }

        i {
            font-size: 22px;
            color: white;
            padding: 9px;
            line-height: 1.2;
        }

        .label-date {
            font-size: 10px;
            float: right;
        }
    }

    .spinner-results {
        top: 30%;
        left: 25%;
    }

    .icon-open {
        position: absolute;
        top: 45%;
        font-size: 24px;
        color: white;
        left: 372px;
        z-index: 5;
    }

    .save-button {
        background-color: $azul-allie;
        color: white;
    }

    &.open {
        left: 50px;
    }

    .sidemenu-container {
        margin-top: 20px;
        padding: 15px;
    }

    .sidemenu-item {
        padding: 20px;
        color: white;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.8);
            cursor: pointer;
        }
    }

    .active {
        background-color: $gray-700;
    }

    label {
        color: white;
    }

    .search-input {

        margin-bottom: 20px;

        input {
            padding-left: 45px;
        }

        .search-icon {
            position: absolute;
            left: 20px;
            color: black;
            z-index: 1;
            line-height: 2.5;
        }
    }

    .subfolder {
        margin-top: 10px;
        background-color: $azul-aleatorio;
    }
}

.modal-tag {

    width: 70vw;
    max-width: 70vw;

    .modal-body {
        text-align: center;
    }

    video {
        width: fit-content;
    }

}

.modal-file {

    width: 70vw;
    max-width: 70vw;

    .modal-body {
        text-align: center;
    }

    video {
        max-width: 700px;
        max-height: 400px;
    }

    .select-folder {
        width: 50%;
        margin: auto;
    }
}
.modal-rotator-blur {

    max-width: fit-content;
    min-width: 700px;

    .modal-body {
        overflow-y: scroll;
    }

	.rotator-blur {

		canvas {
			height: 600px;
		}
	}
}

.rotator-blur {

    .toolbar {

        display: inline-flex;
        position: absolute;
        top: 20vh;
        left: 0vw;
        border-radius: 15px;
        padding: 20px;
        min-width: 420px;

        .right-portion {
            background-color: #DCDCDC;
            margin-right: 20px;
            border-radius: 6px;
            display: inline-flex;
        }

        i {
            font-size: 20px;
            width: 34px;
            height: 34px;
            padding: 7px;
            margin: 7px;
            border-radius: 18px;
            color: white;
            background-color: #B0ACAC;
            text-align: center;

        }
    }
}
.sidemenu-fixed {

	position: absolute;
	top: 0px;
	height: 100%;
	width: 50px;
	z-index: 24;
    height: 100%;
	background-color: white;
    text-align: center;
    padding-top: 20px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    .project-name {
        color: #2F3742;
        font-size: 24px;
        display: block;
        white-space: nowrap;
        margin-top: 20px;
        transform: rotate(450deg);
    }

    i {
        color: #2F3742;
        font-size: 24px;
        margin-top: 10px;
        cursor: pointer;

        &:hover {
            color: #2094E2;
        }
    }
}

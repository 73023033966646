.project-overlay {

    max-width: 85vw;
    width: 85vw;

    .floorplan-menu {

        margin-top: 10px;

        .icon-zoom {
            background: $azul-allie;
            color: white;
            border-radius: 18px;
            font-size: 18px;
            padding: 5px;
            margin-right: 6px;
            margin-bottom: 10px;
            cursor: pointer;
        }
    }

    .project-overlay-header {
        max-height: 400px;
        overflow-y: auto;
    }

    textarea {
        resize: none;
    }

    .check {
        color: $verde-permitido;
        margin-left: 20px;
    }

    .galery-container {
        grid-template-columns: max-content;
    }

    .selected {
        background-color: #2094E2;

        .check {
            color: white;
        }

        td {
            color: white;
            border-radius: 6px;
            border-style: hidden;
            background-color: unset;
        }
    }

    .modal-content {
        min-height: 80vh;

        .modal-body {
            max-height: 80vh;
            overflow-y: auto;
        }
    }

    .subtitles {

        margin-top: 20px;

        .subtitle-item {
            font-weight: 600;
            color: #2F3742;
            display: inline-flex;

            span {
                margin-right: 10px;
            }

            i {
                margin-right: 5px;
                line-height: 1.5;
            }

            &.vinculated-selected i {
                color: forestgreen;
            }

            &.vinculated i {
                color: #2094E2;
            }

            &.unvinculated i {
                color: #dcdcdc;
            }

            &.point-selected i {
                color: #EB4034;
            }
        }
    }

    .buttons-vertical-layout {
        margin-top: 20px;

        button {
            box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
            width: 180px;
            margin-top: 10px;
            background-color: #2094E2;
            color: white;
            border: none;
            border-radius: 6px;
        }

        .checked {
            transform: scale(0.98);
            box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
            -webkit-filter: brightness(70%);
        }
    }

    .canvas-instance {
        margin-top: 30px;
        width: 35% !important;
        height: 60% !important;
        position: fixed !important;
    }

    .ifc-viewer-container {
        position: absolute;
        left: 50%;
        height: 99vh;
        width: 49vw;
        border-left: 5px solid black;

        canvas {
            display: initial !important;
            width: 100% !important;
            height: 100vh !important;
        }
    }

}

.new-modal {
    max-width: 30vw;
    width: 30vw;
}
.sidemenu-360-construction {

	position: absolute;
	top: 430px;
	left: -400px;
	height: calc(100vh - 430px);
	width: 350px;
	z-index: 22;
	transition: left .3s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
	background-color: white;
    color: #2F3742;

    &::-webkit-scrollbar {
        width: 10px;
        background-color: rgb(32, 148, 226);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2094E2;
        border-radius: 20px;
        border: 1px solid white;
    }

	.icon-open {
		position: absolute;
		top: 45%;
		font-size: 24px;
		color: white;
		left: 265px;
		z-index: 5;
	}

	&.open {
		left: 50px;
	}

	.sidemenu-container {
		margin-top: 20px;
	}

	.sidemenu-item {
		padding: 20px;
		color: #2F3742;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 10px;

		&:hover {
			background-color: rgba(0,0,0,0.8);
            color: white;
			cursor: pointer;
		}
	}

    .active {
        background-color: $gray-700;
        color: white;
    }
}
